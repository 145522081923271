<template>
    <!-- fullscreen Header & sticky on scroll -->
    <div id="header" class="header header-fullscreen">
         <div class="home-logo">
           <img src="./assets/logo.png" alt="">
         </div>
         <div class="tag-line">
           <h1>The Ability to build anything. Made Easy.</h1>
         </div>
         <div class="scroll-down">
          <span></span>
          <span></span>
          <span></span>
        </div>
    </div>
    <div class="sticky-content">
        <img src="./assets/logo.png" alt="">
    </div>
</template>

<script>
export default {
}
</script>

<style>
.header {
  background-color:black;
  width:100%;
  height:100%;
  font-family: nunito-sans, sans-serif;
  display:grid;
  grid-template-rows: 70% 20% 10%;
  position: -webkit-sticky; /* Safari */
}

.home-logo {
  width:60%;
  height:60%;
  align-self: flex-end;
  justify-self: center;
}

.home-logo img{
  width:auto;
  height:100%;
}

.tag-line{
  color:white;
  align-self:center;
  font-size: 9px;
}

.header ~ .sticky-content{
    position: absolute;
    top:0px;
    width:100%;
    height:0px;
    background-color: rgb(0, 0, 0);
    transition: all 0.5s ease-in-out;
    pointer-events: none;
    padding:0px;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    
}

.header ~ .sticky-content img{
    opacity:0;
    height:80%;
    width:auto;
    transition: all 0.5s ease-in-out;
    margin-right:61.8px;
}

.header-sticky ~ .sticky-content{
    height: var(--sticky-header-height);

}

.header-sticky ~ .sticky-content img{
    width:auto;
    opacity:1;
}

.scroll-down
{
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.scroll-down span:nth-child(2)
{
    animation-delay: -0.2s;
}
.scroll-down span:nth-child(3)
{
    animation-delay: -0.4s;
}
.scroll-down span
{
    display: block;
    width: 20px;
    height: 20px;
    /* border-bottom: 3px solid #FEE715FF;
    border-right: 3px solid #FEE715FF; */
    border-bottom: 3px solid rgba(255, 255, 255, 0.3);
    border-right: 3px solid rgb(255, 255, 255, 0.3);
    /* border-radius:3px; */
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
@keyframes animate
{
    0%
  {
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%
  {
        opacity: 1;
    }
    100%
  {
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}


@media screen and (max-width: 768px) {
    .header{
        grid-template-rows: 60% 20% 20%;
    }

    .home-logo{
        width:70%;
        height:auto;
        justify-self: center;
        align-self: flex-end;
    }

    .home-logo img{
        width:100%;
        height:auto;
    }   
    .tag-line{
        font-size: 0.5rem;
    }

    .header ~ .sticky-content img{
        margin-right:10px;
    }
}

</style>