<template>
  <div id="appContent">
    <div class="content-wrapper">
        <div class="col-1">
            <div class="person">
                <img src="./assets/yejneshwar.png" alt="">
                    <h1 class="name">Yejneshwar Sivamoorthy</h1>
                    <h3 class="who">Director, Founder</h3>
                    <p class="why">Hardware and software engineer, with a love for solving complex issues</p>
            </div>
            <div class="icons">
                <a class="icon" href="tel:+91 9500089648"><button><img src="./assets/mobile.png" alt=""></button></a>
                <a class="icon" href="mailto:yejneshwar@cucircuits.com"><button><img src="./assets/email.png" alt=""></button></a>
                <a class="icon" href="https://linkedin.com/in/yejneshwar"><button><img src="./assets/linkedin.png" alt=""></button></a>
                <a class="icon" href="https://github.com/yejneshwar"><button><img src="./assets/git.png" alt=""></button></a>
            </div>
        </div>
        <div class="col-2">
            <div class="content">
                <p>Cu Circuits was founded for the sole reason of bringing India to the cutting edge of manufacturing.</p>
            </div>
            <div class="content">
                <p>The Indian manufacturing industry contributed to around 16&#8209;17% of India's GDP and is projected to be one of the fastest&#8209;growing sectors.</p>
            </div>
            <div class="content">
                <p>Traditional methods of placing orders for manufacturing are often time&#8209;consuming, inefficient, and lack transparency, creating bottlenecks and hinderances in the industry's growth.</p>
            </div>
            <div class="content">
                <p>Cu Circuits has built a user&#8209;friendly e&#8209;commerce platform as a one&#8209;stop&#8209;shop for 3D&#8209;printed, CNC machined parts as well as PCB's for rapid&#8209;prototyping needs or production&#8209;scale manufacturing.</p>
            </div>
            <div class="status">
                <h2>Stage</h2>
                <p>Seed</p>
                <h2>Industry</h2>
                <p>E&#8209;commerce, Manufacturing</p>
                <h2>Market Size</h2>
                <p>244.5Bn USD, 8.36% CAGR 2023 &#8209; 2028</p>
                <h2>Investment oppoutunity</h2>
                <p>10 Million USD &#8209; 5 Years</p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#appContent{
  height: 100%;
  width: 100%;
  background-color:black;
}
#appContent h1,#appContent h2,#appContent h3{
    font-family: 'rift-soft', sans-serif; 
    color:black;
}

#appContent p{
    font-family: 'Acumin Pro', sans-serif;
    color:black;
}

.content-wrapper{
  display:grid;
  grid-template-columns: 38.2% 61.8%;
  padding-top:var(--sticky-header-height); /* sticky-header height */
  height:calc(100% - var(--sticky-header-height));
  width:100%;
}
.col-1{
    background-color:rgb(228, 229, 231);
    display:grid;
    grid-template-rows: 90% 10%;
    width:100%;
    
}
.col-2{
    background-color:rgb(200, 201, 206);
    overflow-y: scroll;
}

.icons{
    height:100%;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: space-around;

    
}

.icon{
    height:40%;
    width:auto;
    object-fit: contain;
    border-right:2px solid black;
    flex:1;
}

.icon:last-child{
    border-right:none;
}

.icon button{
    all:unset;
    height:100%;
    width:100%;
}

.icon img{
    height:100%;
    width:auto;
}

.content{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.content p{
    margin:64px;
    font-size:24px;
    font-weight:900;
    text-align: left;
}

.status{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.status h2{
    margin-bottom: 5px;
}

.status p{
    margin-top: 5px;
}

.status h2, .status p{
    width:30%;
    text-align: left;
}

.person{
    /* display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    display:grid;
    grid-template-rows: 50% 10% 10% 30%;
}

.person img{
    background-color: grey;
    aspect-ratio: 1/1;
    height:40%;
    max-height:150px;
    max-width:150px;
    border-radius: 50%;
    object-fit: cover;
    justify-self: center;
    align-self: flex-end;
}

.person .name{
    margin-bottom:0;
}

.person .who{
    font-weight: 500;
    font-style: italic;
    margin:0;
}

.person .why{
    margin:auto 32%;
}


@media screen and (max-width: 768px) {
.content-wrapper {
    grid-template-columns: unset;
    grid-template-rows: 38.2% 61.8%;
}
.person{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.person img{
    background-color: grey;
    width:61.8%;
    height:auto;
    object-fit: cover;
    object-position: top;
    border-radius: 3%;
    max-width: unset;
    margin:0 auto;
}

.person .who{
    font-size: 0.9rem;
}

.person .why{
    margin:0;
    font-size: 1rem;
    display:none;
}

.content p{
    margin:32px;
    font-size:16px;
}

.status h2{
    margin-top: 5px;
}

.status h2, .status p{
    width:60%;
}

}

@media screen and (max-width: 768px) and (max-height: 750px) {
    .col-1{
        grid-template-rows: 80% 20%;
    }
    .person img {
        width:auto;
        height:38.2%;
        border-radius: 5%;
    }

    .person .name{
        font-size: 1rem;
        margin-top:5%;
    }

    .person .who{
        font-size: 0.61rem;
    }

    .status h2{
        font-size: 16px;
        margin-top: 2px;
        margin-bottom: 2px;

    }

    .status p{
        font-size: 12px;
        margin-top:2px;

    }

    .content p{
        font-size:16px;
    }

    .icon{
        height:33%;
    }
}

</style>