<template>
  <div id="app-wrapper" class="app-wrapper">
    <Header></Header>
    <appContent></appContent>
  </div>

</template>

<script>
import Header from './Header.vue'
import appContent from './appContent.vue'

export default {
  name: 'App',
  components: {
    Header,
    appContent
  },
  mounted(){
        console.log("HI");
        document.getElementById("app-wrapper").addEventListener('scroll', this.scrollEvent);
  },
  unmounted(){
      window.removeEventListener('scroll', this.scrollEvent);
  },
  methods:{
      scrollEvent(event){
          let header = document.getElementById("header")
          if(header.clientHeight <= event.target.scrollTop){
              header.classList.add("header-sticky");
          }
          else{
              header.classList.remove("header-sticky");
          }
      }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width:100%;
  height:100%;
  overflow: hidden;
  --sticky-header-height: 100px;
  /* scroll-behavior: smooth; */
}

.app-wrapper{
  height:100%;
  width:100%;
  overflow-y: scroll;
}

.app-wrapper::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
